<template>
  <v-navigation-drawer
    v-model="drawer"
    @input="onDrawer"
    width="300px"
    :permanent="!$root.$session.isMobile"
    fixed
    left
    :mini-variant="$root.$session.mini"
    :hide-overlay="!$root.$session.isMobile"
    :mini-variant-width="$root.$session.miniWidth + 'px'"
    :expand-on-hover="$root.$session.mini"
    light
    id="test"
    style="color: white"
    color="primary"
  >
    <v-list-item v-if="!$root.$session.mini || boolPanelOpen">
      <v-list-item-content>
        <v-list-item-title class="google-font" style="font-size: 130%; color: white">
          {{ this.$root.$session.cliente.strNome }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="$root.$session.usuario" style="color: white">
          Olá, {{ $root.$session.usuario.strNome }}
          <i :class="$utilidade.getIcone('smile')" aria-hidden="true"></i>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-else-if="$root.$session.mini || !boolPanelOpen">
      <v-list-item-content>
        <v-list-item-title class="google-font" style="font-size: 130%">
          <!-- <img
            src="/logo.png"00
            transition="scale-transition"
            style="max-width: 120px; max-height: 50px"
          /> -->
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense light shaped class="text-left">
      <template v-for="(menu, i) in this.$root.$session.menu.filter((x) => x.boolExibir)">
        <v-list-group
          no-action
          v-if="menu.sub_menu.length"
          :key="i"
          :value="$root.$session.isRouteActive(menu.strRota) ? true : false"
          :class="
            $root.$session.isRouteActive(menu.strRota)
              ? 'white--text text--v-list-item--active'
              : ''
          "
          color="white"
        >
          <v-list-item
            slot="activator"
            color="white"
            :class="
              'text-left pa-0 ma-0 ' +
              ($root.$session.isRouteActive(menu.strRota) ? 'v-list-item--active' : '')
            "
          >
            <v-list-item-icon>
              <v-icon
                style="color: white"
                :class="
                  $root.$session.isRouteActive(menu.strRota) ? 'v-list-item--active' : ''
                "
                >{{ menu.strIcone }}</v-icon
              >
            </v-list-item-icon>
            <v-list-item-title style="color: white; font-weight: bold">{{
              menu.strNome
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(sub_menu, j) in menu.sub_menu.filter((x) => x.boolExibir)"
            :key="i + j"
            :to="{ name: sub_menu.strRota }"
            :color="$route.name == sub_menu.strRota ? 'v-list-item--active' : 'white'"
            class="text-left ml-0 pl-8"
            exact
          >
            <v-list-item-icon>
              <v-icon
                style="color: white"
                :class="$route.name == sub_menu.strRota ? 'v-list-item--active' : ''"
                v-text="sub_menu.strIcone"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-title style="color: white; font-weight: bold">{{
              sub_menu.strNome
            }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-if="!menu.sub_menu.length"
          :key="i"
          :to="{ name: menu.strRota }"
          class="google-font my-0 py-0"
          :color="$route.name == menu.strRota ? 'white' : ''"
          style="color: white; font-weight: bold"
        >
          <v-list-item-icon>
            <v-icon style="color: white" v-text="menu.strIcone"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title style="font-weight: bold" v-text="menu.strNome" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <template
      v-slot:append
      v-if="!$root.$session.mini && $root.$session.isMobile && false"
    >
      <div class="pl-2" style="color: white">
        <p class="subtitle-2 google-font">
          Desenvolvido por
          <a
            :href="$root.$session.strUrlAdmin"
            target="_blank"
            style="text-decoration: none"
            >{{ $root.$session.strNomeAdmin }}</a
          >
        </p>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import offline from "v-offline";

export default {
  name: "layoutIndex",
  props: {},
  components: {
    offline,
  },
  inject: ['boolRequiredAuthentication'],
  mounted() {
    let self = this;
    setTimeout(() => {
      self.initialize();
    }, 500);
  },
  data() {
    return {
      isOffline: false,
      boolExibirTitulo: this.$root.$session.isOffline,
      boolPanelOpen: false,
      drawer: this.$root.$session.isMobile
        ? false
        : this.$cookie.get("drawer")
        ? true
        : false,
    };
  },
  watch: {
    "$root.$session.drawer"() {
      this.drawer = this.$root.$session.drawer;
    },
  },
  methods: {
    onHover(ev) {
      this.boolPanelOpen = !this.boolPanelOpen;
    },

    onDrawer(ev) {
      if (!this.$root.$session.isMobile) {
        if (this.$cookie.get("drawer") != null) {
          this.drawer = this.$cookie.get("drawer");
        }
      }
      this.$root.$session.drawer = this.drawer;
    },
    initialize() {
      var test = document.querySelector(".v-navigation-drawer");
      let self = this;

      test.addEventListener("mouseover", function (event) {
        let boolMiniHover =
          this.className.indexOf("v-navigation-drawer--is-mouseover") != -1;
        self.boolPanelOpen = boolMiniHover;
      });

      test.addEventListener("mouseleave", function (event) {
        self.boolPanelOpen = false;
      });
    },

    toggleDrawer() {},
    onClick(e, item) {
      e.stopPropagation();
      if (item.to || !item.href) return;
      this.$vuetify.goTo(item.href);
    },
    darkMode() {
      let metaThemeColor = document.querySelector("meta[name=theme-color]");

      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      if (localStorage) localStorage.setItem("darkMode", this.$vuetify.theme.dark);
      if (this.$vuetify.theme.dark) {
        metaThemeColor.setAttribute("content", "#212121");
      } else {
        metaThemeColor.setAttribute("content", "#0277bd");
      }
    },
    handleConnectivityChange(status) {
      if (status === true) {
        this.isOffline = false;
      } else {
        this.isOffline = true;
      }
    },
  },
};
</script>
