<template>
  <v-app-bar
    app
    :class="this.$vuetify.theme.dark == true ? '' : 'white'"
    :style="
      this.$vuetify.theme.dark
        ? {
            boxShadow: '0 2px 6px 0 rgba(0,0,0,.12), inset 0 -1px 0 0 #272727',
            'margin-left': $root.$session.isMobile || !boolRequiredAuthentication
              ? ''
              : !$root.$session.mini
              ? '300px'
              : $root.$session.miniWidth + 'px',
          }
        : {
            boxShadow: '0 2px 6px 0 rgba(0,0,0,.12), inset  0 -1px 0 0 #dadce0',
            'margin-left': $root.$session.isMobile  || !boolRequiredAuthentication
              ? ''
              : !$root.$session.mini
              ? '300px'
              : $root.$session.miniWidth + 'px',
          }
    "
    color="white"
  >
    <v-tooltip right v-if="boolRequiredAuthentication">
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon
          v-bind="attrs"
          v-on="on"
          aria-label="Hamburger Menu"
          @click="toggleDrawer"
          style="color: #3da3b4"
        />
      </template>
      <span v-if="!$root.$session.drawer"> Clique para fixar o menu na tela </span>
      <span v-if="$root.$session.drawer"> Clique para ocultar o menu da tela </span>
    </v-tooltip>

    <v-toolbar-title
      class="google-font px-0 text-left"
      :style="
        $root.$session.isMobile ? 'width: 100%;color:#3da3b4' : 'width: 80%;color:#3da3b4'
      "
    >
      <router-link
        to="/"
        class="google-font"
        aria-label="Communiy homepage"
        style="text-decoration: none; font-size: 110%; color: #3da3b4 !important"
        :class="this.$vuetify.theme.dark ? 'whiteText' : 'whiteText'"
      >
        <img :src="this.$root.$session.cliente.strUrlLogo ? this.$root.$session.cliente.strUrlLogo : '/logo.png'" style="max-width: 250px; max-height: 50px" />
        <span class="hidden-sm-and-down ma-0 pa-0">
          {{ this.$root.$session.cliente.strNome }}
        </span>
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>


    <template v-if="!$root.$session.isMobile && boolRequiredAuthentication">
      <v-menu offset-y tile bottom origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <ed-button
            class=""
            color="primary"
            onlyIcon
            small
            text
            v-bind="attrs"
            v-on="on"
            :icon-left="$utilidade.getIcone('usuario')"
          />
        </template>
        <v-list>
          <v-list-item link exact :to="{ name: 'meucadastro' }">
            <v-list-item-icon class="mr-3">
              <v-icon v-text="$utilidade.getIcone('usuario')"></v-icon>
            </v-list-item-icon>
            <v-list-item-title > Meu cadastro</v-list-item-title>
          </v-list-item>
          <v-list-item link exact :to="{ name: 'logout' }">
            <v-list-item-icon class="mr-3">
              <v-icon v-text="$utilidade.getIcone('logout')"></v-icon>
            </v-list-item-icon>
            <v-list-item-title> Sair </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!--NOTIFICATION-->
      <v-menu offset-y tile bottom origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <ed-badge
            class=""
            color="primary"
            small
            onlyIcon
            text
            v-bind="attrs"
            v-on="on"
            :icon-left="$utilidade.getIcone('notificacao')"
            :badge="$root.$session.notification.length"
          />
        </template>
        <v-list max-height="500" flat>
          <v-list-item
            v-for="(objNotificacao, index) in $root.$session.notification"
            :key="index"
          >
            <v-list-item-icon class="mr-3" v-if="objNotificacao.strIcone">
              <v-icon
                class="grey lighten-1"
                dark
                v-text="$utilidade.getIcone(objNotificacao.strIcone)"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-avatar class="mr-3" v-else-if="!objNotificacao.strIcone">
              <v-icon
                class="grey lighten-1"
                dark
                v-text="$utilidade.getIcone('info')"
              ></v-icon>
            </v-list-item-avatar>
            <v-list-item-title>
              <span>{{ objNotificacao.strTitulo }}</span>
            </v-list-item-title>
            <v-list-item-action>
              <ed-button
          
                :iconLeft="$utilidade.getIcone('deletar')"
                color="error"
                title="Deletar"
                icon
                onlyIcon
                @click="onDeleteNotification(objNotificacao)"
              />
            </v-list-item-action>
          </v-list-item>

          <v-list-item v-if="!$root.$session.notification.length">
            <v-list-item-title> Nenhuma notificação no momento</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script>
import offline from "v-offline";
import { EdButton,EdBadge } from "@/components/common/form";

export default {
  name: "layoutIndex",
  props: {},
  components: {
    offline,
    EdButton,
    EdBadge
  },
  inject: ['boolRequiredAuthentication'],
  computed: {
    iconeMenu: {
      cache: false,
      get(boolAberto) {
        if (boolAberto) {
          return $utilidade.getIcone("setaBaixo");
        }

        return $utilidade.getIcone("setaCima");
      },
    },
  },
  data() {
    return {
      isOffline: false,
      config: {
        generalConfig: {
          socialLinks: {},
        },
        keysandsecurity: {},
        footerConfig: {},
      },
      bind: {
        arrayNotificacao: [],
      },
    };
  },
  mounted() {},
  methods: {
    onDeleteNotification(obj) {},

    toggleDrawer() {
      this.$root.$session.drawer = !this.$root.$session.drawer;

      if (!this.$root.$session.isMobile) {
        this.$root.$session.mini = !this.$root.$session.drawer;
      }

      if (!this.$root.$session.isMobile) {
        this.$cookie.set("drawer", this.$root.$session.drawer, {
          expires: 24 * 30 + "h",
        });
      }
    },
    onClick(e, item) {
      e.stopPropagation();
      if (item.to || !item.href) return;
      this.$vuetify.goTo(item.href);
    },
    darkMode() {
      let metaThemeColor = document.querySelector("meta[name=theme-color]");

      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      if (localStorage) localStorage.setItem("darkMode", this.$vuetify.theme.dark);
      if (this.$vuetify.theme.dark) {
        metaThemeColor.setAttribute("content", "#212121");
      } else {
        metaThemeColor.setAttribute("content", "#0277bd");
      }
    },
    handleConnectivityChange(status) {
      if (status === true) {
        this.isOffline = false;
      } else {
        this.isOffline = true;
      }
    },
  },
};
</script>
