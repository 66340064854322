<template>
   <v-bottom-navigation
    grow
    app
    :dark="this.$vuetify.theme.dark"
    :background-color="this.$vuetify.theme.dark?'#212121':'white'"
    :color="this.$vuetify.theme.dark?'white':'primary'"
    class="hidden-md-and-up"
  > 
    <!-- <v-btn
        v-for="(link, i) in arrayMenu.filter(res=>res.meta.showBottomNav)" :key="i"
        :to="link.to"
        small
        class="ml-0 google-font"
        style="text-transform: capitalize;" 
        text
        @click="onClick($event, link)"
    >
        <span>{{ link.text }}</span>
        <v-icon>{{ link.icon }}</v-icon>
    </v-btn> -->
  </v-bottom-navigation>
</template>

<script>
import offline from "v-offline";

export default {
  name: 'layoutIndex',
  props: {
  },
  components:{
    offline
  },
  inject:[
  ],
  computed: {
    drawer: {
      get() {
        return this.$store.state.menu.drawer;
      },
      set(val) {
        this.$store.dispatch("menu/setDrawer", val);
      }
    }
  },
  data() {
    return {
      isOffline: false,
    };
  },
   methods: {
    toggleDrawer(){

    },
    onClick(e, item) {
      e.stopPropagation();
      if (item.to || !item.href) return;
      this.$vuetify.goTo(item.href);
    },
    darkMode() {
      let metaThemeColor = document.querySelector("meta[name=theme-color]");

      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      if (localStorage)
        localStorage.setItem("darkMode", this.$vuetify.theme.dark);
      if (this.$vuetify.theme.dark) {
        metaThemeColor.setAttribute("content", "#212121");
      } else {
        metaThemeColor.setAttribute("content", "#0277bd");
      }
    },
    handleConnectivityChange(status) {
      if (status === true) {
        this.isOffline = false;
      } else {
        this.isOffline = true;
      }
    }
  }
  
}
</script>

