<template>
  <div>
    <Desktop v-if="!$route.meta.isEvent" :key="'ed-menu-desktop-' + $root.$session.versao"  />
    <Mobile v-if="!$route.meta.isEvent && boolRequiredAuthentication && this.$root.$session.usuario" :key="'ed-menu-mobile-' + $root.$session.versao"  />
    <!-- <Bottom  v-if="!$route.meta.isEvent"/> -->
  </div>
</template>

<script>
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import Bottom from "./Bottom";

export default {
  name: "layoutIndex",
  props: {},
  components: {
    Desktop,
    Mobile,
    Bottom,
  },
  provide() {
    return {
      boolRequiredAuthentication:this.boolRequiredAuthentication
    };
  },
  inject: [],
  computed: {
    boolRequiredAuthentication() {
      return this.$route.meta?.boolRequiredAuthentication ? true : false;
    },
  },
  data() {
    return {
      isOffline: false,
    };
  },
  mounted() {

  },
  methods: {},
};
</script>

