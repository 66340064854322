<template>
  <div>
    <div v-if="boolCarregado">
      <div>
        <v-main
          v-if="!boolTopDisble"
          :class="this.$root.$session.isMobile ? 'pa-0' : 'pa-0'"
        >
          <v-container class="fill-height">
            <v-row justify="center" align="center" class>
              <v-col cols="12" md="12" class="text-center pa-0 ma-0">
                <ed-menu
                  class="ed-menu"
                  :key="'ed-menu-' + $root.$session.versao"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-main>

        <v-card
          v-if="boolRequiredAuthentication && this.$root.$session.logado()"
          elevation="1"
          rounded
          :class="
            this.$root.$session.isMobile
              ? 'main-card fill-height mt-1 ml-1 mr-1 mb-2 pr-2 pl-2 '
              : 'main-card fill-height mr-2 ml-2 mb-15 pt-0 pr-4 pl-4'
          "
          :loading="$root.$session.loadingContent"
          :style="
            'min-height: 100% !important;margin-left:' +
            ($root.$session.isMobile
              ? ''
              : !$root.$session.mini
              ? '305px !important'
              : $root.$session.miniWidth + 7 + 'px !important')
          "
        >
          <v-card-title class="pl-0">
            <span class="col-12 pa-0" style="color: #595959">
              <b
                v-if="
                  !$route.meta.menu &&
                  $root.$session.usuario &&
                  ['index', 'home'].indexOf($route.name) != -1
                "
              >
                {{ $utilidade.getSaudacao() }},
                {{ $root.$session.usuario.strNome }}
              </b>
              <b v-else-if="$route.meta.menu">
                <ed-icone
                  :strIcone="$route.meta.menu.icon"
                  :key="$route.meta.menu.icon"
                />
                {{ $route.meta.menu.name }}
              </b>
            </span>
            <span class="text-right">
              <v-breadcrumbs :items="menuNavigation" class="pa-0 text-right">
                <template v-slot:divider>
                  <v-icon>{{ $utilidade.getIcone("setaDireita") }}</v-icon>
                </template>
                <template v-slot:item="{ item }">
                  <v-breadcrumbs-item :disabled="item.disabled">
                    <router-link
                      v-if="item.to"
                      :to="{ name: item.to }"
                      style="text-decoration: none"
                      >{{ item.text }}</router-link
                    >
                    <span v-else>{{ item.text }}</span>
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>
            </span>
          </v-card-title>
          <v-fade-transition>
            <div class="pb-3">
              <router-view />
            </div>
          </v-fade-transition>
        </v-card>
        <div v-else>
          <v-fade-transition>
            <div class="">
              <router-view />
            </div>
          </v-fade-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EdMenu from "@/components/common/menu/Index";
import Login from "@/views/Cliente/Login";
import { EdIcone } from "@/components/common/form";

export default {
  name: "layoutIndex",
  components: { EdMenu, Login, EdIcone },
  props: {},
  watch: {
    $route() {},
    "$root.$session.usuario"() {},
    boolCarregado(){
      this.$root.$session.loadingContent = !this.boolCarregado;
    }
  },
  computed: {
    boolRequiredAuthentication() {
      return this.$route.meta?.boolRequiredAuthentication ? true : false;
    },
    boolTopDisble() {
      return this.$route.meta?.boolTopDisble ? true : false;
    },
    boolFooterDisble() {
      return this.$route.meta?.boolFooterDisble ? true : false;
    },
    menuNavigation() {
      if (this.$route.meta.menuNavigation) {
        return this.$route.meta.menuNavigation;
      }
      return [];
    },
  },
  provide() {
    return {};
  },
  created() {

    if(this.$cookie.get("customer_name") != this.$route.params.strUrlDatabase){
      this.$cookie.set("customer_name",this.$route.params.strUrlDatabase)
      this.$root.$session.logout();
      return;
    }

    this.$root.$session.layout = "cliente";

    if (!this.$root.$session.cliente) {
      this.getCliente();
    } else {
      this.validarLogin();
    }
  },
  mounted() {
    this.initialize();
  },
  beforeRouteUpdate(to, from, next) {
    this.validarLogin(to, from, next);
  },
  beforeDestroy() {
    this.$root.$socket.unsubscribe("notification");
  },
  data() {
    return {
      boolCarregado: false,
    };
  },
  methods: {
    initialize() {
    },

    getCliente() {
      this.$root.$request
        .get(
          (!this.$root.$session.cliente
            ? this.$route.params.strUrlDatabase + "/"
            : "") + "publico/cliente",
          {
            boolInicializar: true,
            strUrlDatabase: this.$route.params.strUrlDatabase,
          }
        )
        .then((objResultado) => {
          if (objResultado.status == 200 && objResultado.result) {
            this.$root.$session.cliente = objResultado.result;
            this.validarLogin();

            let self = this

            this.$root.$socket.subscribe("notification", "uploadCliente", function (e) {
              self.getCliente();
            });

          } else {
            this.$cookie.delete(process.env.VUE_APP_CLIENTE_COOKIE_NAME);
          }
        });
    },

    getUsuario(next) {
      this.$root.$request
        .get("Cadastro/Usuario", {
          boolMeuCadastro: true,
        })
        .then((objResultado) => {

          let self = this;

          if (objResultado.status == 200 && objResultado.result) {
            this.$root.$session.usuario = objResultado.result;
            this.getNotificacao();
            
            this.$root.$socket.subscribe("notification", "user", function (e) {
              self.getNotificacao();
            });
          }

          if (next) {
            next();
          }
          this.boolCarregado = true;
        });
    },

    getNotificacao() {
      this.$root.$session.notification = [];

      this.$root.$request
        .get("Common/Notificacao", {
          pointId: this.$root.$session.usuario.intId,
          pointType: "usuario",
        })
        .then((objResponse) => {
          if (objResponse.status == 200 && objResponse.result) {
            this.$root.$session.notification = objResponse.result.data;
          }
        });
    },

    menuAtual() {},

    validarLogin(to, from, next) {
      if (!to) to = this.$route;

      if (to.meta.menu) {
        document.title = to.meta.menu.name;
      }

      if (
        !to.matched.some((record) => record.meta.boolRequiredAuthentication)
      ) {
        this.boolCarregado = true;
        if (next) next();
      } else {
        if (!this.$root.$session.usuario) {
          this.getUsuario(next);
        } else if (!this.$root.$session.logado()) {
          //this.$router.push({ name: "loginCliente" });
          this.boolCarregado = true;
        } else {
          if (next) next();
          this.boolCarregado = true;
        }
      }
    },
  },
};
</script>
