var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{class:this.$vuetify.theme.dark == true ? '' : 'white',style:(this.$vuetify.theme.dark
      ? {
          boxShadow: '0 2px 6px 0 rgba(0,0,0,.12), inset 0 -1px 0 0 #272727',
          'margin-left': _vm.$root.$session.isMobile || !_vm.boolRequiredAuthentication
            ? ''
            : !_vm.$root.$session.mini
            ? '300px'
            : _vm.$root.$session.miniWidth + 'px',
        }
      : {
          boxShadow: '0 2px 6px 0 rgba(0,0,0,.12), inset  0 -1px 0 0 #dadce0',
          'margin-left': _vm.$root.$session.isMobile  || !_vm.boolRequiredAuthentication
            ? ''
            : !_vm.$root.$session.mini
            ? '300px'
            : _vm.$root.$session.miniWidth + 'px',
        }),attrs:{"app":"","color":"white"}},[(_vm.boolRequiredAuthentication)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({staticStyle:{"color":"#3da3b4"},attrs:{"aria-label":"Hamburger Menu"},on:{"click":_vm.toggleDrawer}},'v-app-bar-nav-icon',attrs,false),on))]}}],null,false,2766009761)},[(!_vm.$root.$session.drawer)?_c('span',[_vm._v(" Clique para fixar o menu na tela ")]):_vm._e(),(_vm.$root.$session.drawer)?_c('span',[_vm._v(" Clique para ocultar o menu da tela ")]):_vm._e()]):_vm._e(),_c('v-toolbar-title',{staticClass:"google-font px-0 text-left",style:(_vm.$root.$session.isMobile ? 'width: 100%;color:#3da3b4' : 'width: 80%;color:#3da3b4')},[_c('router-link',{staticClass:"google-font",class:this.$vuetify.theme.dark ? 'whiteText' : 'whiteText',staticStyle:{"text-decoration":"none","font-size":"110%","color":"#3da3b4 !important"},attrs:{"to":"/","aria-label":"Communiy homepage"}},[_c('img',{staticStyle:{"max-width":"250px","max-height":"50px"},attrs:{"src":this.$root.$session.cliente.strUrlLogo ? this.$root.$session.cliente.strUrlLogo : '/logo.png'}}),_c('span',{staticClass:"hidden-sm-and-down ma-0 pa-0"},[_vm._v(" "+_vm._s(this.$root.$session.cliente.strNome)+" ")])])],1),_c('v-spacer'),(!_vm.$root.$session.isMobile && _vm.boolRequiredAuthentication)?[_c('v-menu',{attrs:{"offset-y":"","tile":"","bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('ed-button',_vm._g(_vm._b({attrs:{"color":"primary","onlyIcon":"","small":"","text":"","icon-left":_vm.$utilidade.getIcone('usuario')}},'ed-button',attrs,false),on))]}}],null,false,1038154230)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","exact":"","to":{ name: 'meucadastro' }}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.$utilidade.getIcone('usuario'))}})],1),_c('v-list-item-title',[_vm._v(" Meu cadastro")])],1),_c('v-list-item',{attrs:{"link":"","exact":"","to":{ name: 'logout' }}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.$utilidade.getIcone('logout'))}})],1),_c('v-list-item-title',[_vm._v(" Sair ")])],1)],1)],1),_c('v-menu',{attrs:{"offset-y":"","tile":"","bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('ed-badge',_vm._g(_vm._b({attrs:{"color":"primary","small":"","onlyIcon":"","text":"","icon-left":_vm.$utilidade.getIcone('notificacao'),"badge":_vm.$root.$session.notification.length}},'ed-badge',attrs,false),on))]}}],null,false,67129528)},[_c('v-list',{attrs:{"max-height":"500","flat":""}},[_vm._l((_vm.$root.$session.notification),function(objNotificacao,index){return _c('v-list-item',{key:index},[(objNotificacao.strIcone)?_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{staticClass:"grey lighten-1",attrs:{"dark":""},domProps:{"textContent":_vm._s(_vm.$utilidade.getIcone(objNotificacao.strIcone))}})],1):(!objNotificacao.strIcone)?_c('v-list-item-avatar',{staticClass:"mr-3"},[_c('v-icon',{staticClass:"grey lighten-1",attrs:{"dark":""},domProps:{"textContent":_vm._s(_vm.$utilidade.getIcone('info'))}})],1):_vm._e(),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(objNotificacao.strTitulo))])]),_c('v-list-item-action',[_c('ed-button',{attrs:{"iconLeft":_vm.$utilidade.getIcone('deletar'),"color":"error","title":"Deletar","icon":"","onlyIcon":""},on:{"click":function($event){return _vm.onDeleteNotification(objNotificacao)}}})],1)],1)}),(!_vm.$root.$session.notification.length)?_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Nenhuma notificação no momento")])],1):_vm._e()],2)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }