var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.boolCarregado)?_c('div',[_c('div',[(!_vm.boolTopDisble)?_c('v-main',{class:this.$root.$session.isMobile ? 'pa-0' : 'pa-0'},[_c('v-container',{staticClass:"fill-height"},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-center pa-0 ma-0",attrs:{"cols":"12","md":"12"}},[_c('ed-menu',{key:'ed-menu-' + _vm.$root.$session.versao,staticClass:"ed-menu"})],1)],1)],1)],1):_vm._e(),(_vm.boolRequiredAuthentication && this.$root.$session.logado())?_c('v-card',{class:this.$root.$session.isMobile
            ? 'main-card fill-height mt-1 ml-1 mr-1 mb-2 pr-2 pl-2 '
            : 'main-card fill-height mr-2 ml-2 mb-15 pt-0 pr-4 pl-4',style:('min-height: 100% !important;margin-left:' +
          (_vm.$root.$session.isMobile
            ? ''
            : !_vm.$root.$session.mini
            ? '305px !important'
            : _vm.$root.$session.miniWidth + 7 + 'px !important')),attrs:{"elevation":"1","rounded":"","loading":_vm.$root.$session.loadingContent}},[_c('v-card-title',{staticClass:"pl-0"},[_c('span',{staticClass:"col-12 pa-0",staticStyle:{"color":"#595959"}},[(
                !_vm.$route.meta.menu &&
                _vm.$root.$session.usuario &&
                ['index', 'home'].indexOf(_vm.$route.name) != -1
              )?_c('b',[_vm._v(" "+_vm._s(_vm.$utilidade.getSaudacao())+", "+_vm._s(_vm.$root.$session.usuario.strNome)+" ")]):(_vm.$route.meta.menu)?_c('b',[_c('ed-icone',{key:_vm.$route.meta.menu.icon,attrs:{"strIcone":_vm.$route.meta.menu.icon}}),_vm._v(" "+_vm._s(_vm.$route.meta.menu.name)+" ")],1):_vm._e()]),_c('span',{staticClass:"text-right"},[_c('v-breadcrumbs',{staticClass:"pa-0 text-right",attrs:{"items":_vm.menuNavigation},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v(_vm._s(_vm.$utilidade.getIcone("setaDireita")))])]},proxy:true},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"disabled":item.disabled}},[(item.to)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: item.to }}},[_vm._v(_vm._s(item.text))]):_c('span',[_vm._v(_vm._s(item.text))])],1)]}}],null,false,3490577494)})],1)]),_c('v-fade-transition',[_c('div',{staticClass:"pb-3"},[_c('router-view')],1)])],1):_c('div',[_c('v-fade-transition',[_c('div',{},[_c('router-view')],1)])],1)],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }